@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Hermes Maia";
        font-style: normal;
        font-weight: 800;
        font-display: block;
        src: url(/src/fonts/hermes_maia_8_regular.woff2) format('woff2');
    }
}